// import Router from "./Router";
// import { PersistGate } from "redux-persist/integration/react";
// import { persistor, store } from "./Redux/store";
// import { Provider } from "react-redux";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// function App() {
//   return (
//     <PersistGate loading={null} persistor={persistor}>
//       <Provider store={store}>
//         <Router />
//         <ToastContainer />
//       </Provider>
//     </PersistGate>
//   )
// }

// export default App;

import React, { useEffect } from "react";
import Router from "./Router";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./Redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/* global fbq */ // Declare fbq as global

function App() {
  useEffect(() => {
    // Facebook Pixel Base Code
    const initFacebookPixel = () => {
      // Define the self-invoking function
      (function(f, b, e, v, n, t, s) {
        if (f.fbq) return; 
        n = f.fbq = function() {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n; 
        n.push = n; 
        n.loaded = !0; 
        n.version = '2.0';
        n.queue = []; 
        t = b.createElement(e); 
        t.async = !0;
        t.src = v; 
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'); // Invoke it here

      // Initialize with your Pixel ID
      fbq('init', '524095243846724'); 
      fbq('track', 'PageView');

      // Add NoScript fallback
      const fbNoScript = document.createElement('noscript');
      fbNoScript.innerHTML = `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=524095243846724&ev=PageView&noscript=1" />
      `;
      document.body.appendChild(fbNoScript);
    };

    initFacebookPixel(); // Call the function to initialize Facebook Pixel
  }, []);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <Router />
        <ToastContainer />
      </Provider>
    </PersistGate>
  );
}

export default App;
